
import React from 'react';
import Cookies from 'universal-cookie';
import Logo from '../../src/static/Logo.jpg';
import Box from '@mui/material/Box';
import {
  Button,
  Grid,
} from '@mui/material';
import AccountMenu from '../components/AccountMenu.js';
import { callMsGraph } from '../graph';
import {
  useMsal,
  AuthenticatedTemplate
} from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const cookies = new Cookies();

const Menu = () => {
  const [superUser, setSuperUser] = React.useState(false);
  const consultaDoc = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('apellido_paterno', { path: '/' });
    cookies.remove('apellido_materno', { path: '/' });
    cookies.remove('nombre', { path: '/' });
    cookies.remove('username', { path: '/' });
    cookies.remove('tipodoc', { path: '/' });
    cookies.remove('fechainical', { path: '/' });
    cookies.remove('fechafinal', { path: '/' });
    window.location.href = './consulta';
  };
  const mantencionUsuarios = () => {
    cookies.remove('id', { path: '/' });
    cookies.remove('apellido_paterno', { path: '/' });
    cookies.remove('apellido_materno', { path: '/' });
    cookies.remove('nombre', { path: '/' });
    cookies.remove('username', { path: '/' });
    cookies.remove('tipodoc', { path: '/' });
    cookies.remove('fechainical', { path: '/' });
    cookies.remove('fechafinal', { path: '/' });
    window.location.href = './mantencionUsuarios';
  };
  React.useEffect(() => {
    if (cookies.get('userType') === '1') {
      setSuperUser(false);
    } else {
      setSuperUser(true);
    }
  }, []);
  const { instance, accounts } = useMsal();
  const [setGraphData] = React.useState(null);
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then(response => {
      callMsGraph(response.accessToken).then(response => setGraphData(response));
    }).catch((e) => {
      instance.acquireTokenPopup(request).then(response => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    });
  };
  return (
    <>
      <AuthenticatedTemplate>
        <Box style={{ background: 'linear-gradient(#FFFFFF 30%, #2596be)', height: '100%', width: '100%' }}>
          <Grid
            container
            textAlign="center"
            rowSpacing={5}
            gap={1}
            id="PageGrid"
          >
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={2} textAlign="left" >
              <img
                src={Logo}
                alt={'n'}
                width={50} height={50}
                style={{ borderRadius: '50%' }}
              />
            </Grid>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <AccountMenu />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={5}>
            </Grid>
            <Grid container textAlign="Center" rowSpacing={1} xs={2}>
              <Grid item xs={12}>
                <Button variant="contained" onClick={() => {
                  consultaDoc();
                }} style={{ maxWidth: '250px', maxHeight: '40px', minWidth: '250px', minHeight: '40px' }}> Consulta documentos </Button>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" disabled={superUser} onClick={() => {
                  mantencionUsuarios();
                }} style={{ maxWidth: '250px', maxHeight: '40px', minWidth: '250px', minHeight: '40px' }}> Mantencion usuarios </Button>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
            <Grid item xs={5}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </Box>
      </AuthenticatedTemplate>
    </>
  );
};

export default Menu;


const client_id = process.env.REACT_APP_URL_CLIENT_ID;
const authority_url = process.env.REACT_APP_URL_AUTHORITY;
const redirect_url = process.env.REACT_APP_URL_REDIRECT_URI;
const graphme_endpoint = process.env.REACT_APP_URL_GRAPHME_ENDPOINT;

export const msalConfig = {
    auth: {
      clientId: client_id,
      authority: authority_url,
      redirectUri: redirect_url
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };

  export const loginRequest = {
   scopes:['User.Read']
  };

  export const graphConfig = {
      graphMeEndpoint: graphme_endpoint
  };

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Logo from '../../src/static/Logo.jpg';
import AccountMenu from '../components/AccountMenu.js';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import InputIcon from '@mui/icons-material/Input';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField,
  DialogContentText
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const baseUrl = process.env.REACT_APP_URL_DOCUMENTS_GET;
const baseUrl_post = process.env.REACT_APP_URL_DOCUMENTS_POST;
let rows = [];

const documentBoF = [
  {
    value: 'BLT',
    label: 'BLT'
  },
  {
    value: 'FME',
    label: 'FAC'
  }
];

let objEnvia = {};

const Consulta = () => {

  const [dialogDocumentFound, setDialogDocumentFound] = React.useState(false);
  const [dialogDocumentRepeat, setDialogDocumentRepeat] = React.useState(false);
  const [listaDoc, setListaDoc] = React.useState([]);
  const [listaEleCol, setListaEleCol] = React.useState(0);
  const [dialogDocumentNotFound, setDialogDocumentNotFound] = React.useState(false);
  const [dialogFieldValidation, setDialogFieldValidation] = React.useState(false);
  const [dialogDocumentSend, setDialogDocumentSend] = React.useState(false);
  const [dialogDocumentNotSend, setDialogDocumentNotSend] = React.useState(false);
  const [consultaForm, setConsultaForm] = React.useState({
    tipoDoc: '',
    sucursal: '',
    correlativo: ''
  });
  const [responseData, setResponseData] = React.useState([]);
  const { instance, accounts } = useMsal();
  const handleChange = async e => {
    setConsultaForm({
      ...consultaForm,
      [e.target.name]: e.target.value
    });
  };
  const [documentTypeState, setDocumentTypeState] = React.useState('BLT');
  const handleChangeDocumentType = event => {
    setDocumentTypeState(event.target.value);
  };
  const _upsPopUp = 'Ups!, algo salió mal...';
  const consultaDoc = async () => {
    try {
      const URL = baseUrl + '/' + documentTypeState + '/' + consultaForm.sucursal + '/' + consultaForm.correlativo;
      if (consultaForm.sucursal === '' || consultaForm.correlativo === '') {
        setDialogFieldValidation(true);
        return 1;
      }
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then(async response => {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': response.tokenType + ' ' + response.idToken
          };

          await axios.get(URL, { headers })
            .catch(e => {
              setDialogDocumentNotFound(true);
            })
            .then(response => {
              if (typeof response === 'undefined') {
                setDialogDocumentNotFound(true);
              } else {
                setResponseData(response.data);
                if (response.data.pdf !== 'hola mundo') {
                  for (const document of listaDoc) {
                    if (document.correlativo === response.data.correlativo) {
                      setDialogDocumentRepeat(true);
                      return response.data
                    }
                  }
                  const date = new Date(response.data.fecha)
                  rows = [...rows, {
                    id: listaEleCol, idDB: response.data.id, tipodoc: response.data.tipodoc, sucursal: response.data.sucursal, correlativo: response.data.correlativo,
                    mail: response.data.mail, fecha: date.toLocaleDateString(), estado: response.data.estado, pdf: response.data.pdf, link: response.data.link, asPdf: response.data.pdf ? 'si' : 'no'
                  }];
                  setListaDoc(rows);
                  setListaEleCol(listaEleCol + 1);
                } else {
                  setDialogDocumentFound(true);
                }
                return response.data;
              }
            });
        }
        )
    }
    catch (e) {
      setDialogDocumentNotFound(true);
    }
  };
  const asignaRequestBody = obj => {
    return obj[0].idDB;
  };

  const enviaDoc = async () => {
    const idDB = asignaRequestBody(objEnvia);
    for (const document of listaDoc) {
      if (objEnvia[0].id === document.id) {
        if(!document.pdf){
          setDialogDocumentFound(true)
          rows = deleteRow(objEnvia[0].id);
          setListaDoc(rows);
          return;
        }
      }
    }
    try {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then(async (response) => {

          const headers = {
            'Authorization': response.tokenType + ' ' + response.idToken,
          };
          await axios.post(baseUrl_post + '/' + idDB, null, { headers })
            .catch(e => {
              setDialogDocumentNotSend(true);
            })
            .then(response => {
              if (response.status === '500') {
                setDialogDocumentNotSend(true);
              } else {
                setDialogDocumentSend(true);
                let id = objEnvia[0].id;
                rows = deleteRow(id);
                setListaDoc(rows);
                return response.data;
              }
            });
        });
    }
    catch (e) {
      setDialogDocumentNotSend(true);
    }
  };

  const volver = () => {
    window.location.href = './Menu';
  };

  const deleteRow = idToDelete => {
    const index = rows.findIndex(x => x.id === idToDelete);
    let _rowsTemp = [];
    let j = 0;
    for (let i = 0; i < rows.length; i++) {
      if (i !== index) {
        _rowsTemp[j] = rows[i];
        j++;
      }
    }
    rows = _rowsTemp;
    return rows;
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90, editable: false, hide: true, headerAlign: 'center'},
    { field: 'tipodoc', headerName: 'Tipo documento', width: 130, editable: false, headerAlign: 'center' },
    {
      field: 'sucursal',
      headerName: 'Sucursal',
      description: 'Sucursar donde se emitió documento.',
      width: 80,
      editable: false,
      align: 'right',
      headerAlign: 'center'
    },
    {
      field: 'correlativo',
      headerName: 'Correlativo',
      description: 'Correlativo de documento.',
      width: 150,
      editable: false,
      align: 'right',
      headerAlign: 'center'
    },
    {
      field: 'mail',
      headerName: 'Mail',
      description: 'mail asociado a documento.',
      width: 300,
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'fecha',
      headerName: 'Fecha de registro',
      description: 'Fecha de registro de documento.',
      width: 200,
      headerAlign: 'center'
    },
    {
      field: 'asPdf',
      headerName: 'PDF',
      description: 'Existencia de pdf.',
      width: 50,
      headerAlign: 'center'
    }

  ];
  return (
    <>
      <AuthenticatedTemplate>
        <Box style={{ background: 'linear-gradient(#FFFFFF 10%, #2596be)', height: '100%', width: '100%' }}>
          <Grid
            container
            textAlign="center"
            rowSpacing={2}
            gap={1}
            id="PageGrid"
          >

            <Grid item xs={1}>

            </Grid>
            <Grid item xs={2} textAlign="left" >
              <img
                src={Logo}
                width={50} height={50}
                style={{ borderRadius: '50%' }}
                alt=''
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" endIcon={<ArrowBackIcon />} onClick={() => {
                volver();
              }
              } style={{ maxWidth: '150px', maxHeight: '40px', minWidth: '150px', minHeight: '40px' }}> volver </Button>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <AccountMenu />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid
              container
              textAlign="center"
              rowSpacing={2}
              gap={1}
              id="QueryGrid"
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <TextField id="outlined-basic" label="Tipo documento" variant="outlined"
                  select
                  className="form-control"
                  name="tipoDoc"
                  value={documentTypeState}
                  onChange={handleChangeDocumentType}
                >
                  {documentBoF.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField id="outlined-basic" label="Sucursal" variant="outlined"
                  type="text"
                  className="form-control"
                  name="sucursal"
                  value={consultaForm.sucursal}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField id="outlined-basic" label="Correlativo" variant="outlined"
                  type="text"
                  className="form-control"
                  name="correlativo"
                  value={consultaForm.correlativo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" endIcon={<FindInPageIcon />} onClick={() => {
                  consultaDoc();
                }} style={{ maxWidth: '250px', maxHeight: '55px', minWidth: '250px', minHeight: '55px' }}> Consulta  documento </Button>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Grid>
          <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={listaDoc}
              disableColumnMenu={true}
              column
              onSelectionModelChange={ids => {
                const selectedIDs = new Set(ids);
                const selectedRowData = listaDoc.filter(row =>
                  selectedIDs.has(row.id));
                objEnvia = selectedRowData;
              }}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              style={{ background: '#FFFFFF' }}
            />
          </Box>
          <Grid
            container
            textAlign="center"
            rowSpacing={2}
            height={207}
            gap={1}
            id="Grid"
          >
            <Grid item xs={24} marginTop={5}>
              <Button variant="contained" endIcon={<InputIcon />} onClick={() => {
                enviaDoc();
              }
              } style={{ maxWidth: '250px', maxHeight: '55px', minWidth: '250px', minHeight: '55px' }}> Envia  documento </Button>
            </Grid>
            <Dialog
              open={dialogDocumentFound}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Error'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Documento número {responseData.correlativo}, sin pdf
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogDocumentFound(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogDocumentRepeat}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Documento encontrado!'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Documento número {responseData.correlativo}, ya se encuentra en la lista
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogDocumentRepeat(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogDocumentNotFound}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {_upsPopUp}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Documento no encontrado, favor reintentar con documento valido.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogDocumentNotFound(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogDocumentSend}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Todo correcto!'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Documento enviado exitosamente.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogDocumentSend(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogDocumentNotSend}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {_upsPopUp}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Documento no recibido, favor reintentar nuevamente.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogDocumentNotSend(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={dialogFieldValidation}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {_upsPopUp}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Los campos a enviar no pueden estar vacios.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogFieldValidation(false)}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Box>
      </AuthenticatedTemplate>
    </>
  );

};

export default Consulta;
